import React from "react";
import { Link } from "gatsby";

const NotFoundPage: React.FC = (props) => {
    return (
        <div className="h-full w-full flex justify-center items-center">
            <div className="bg-white shadow sm:rounded-lg w-full">
                <div className="px-4 py-5 sm:p-6 text-center">
                    <h1 className="text-3xl font-bold">404</h1>
                    <p>Hakemaasi sivua ei löytynyt!</p>
                    <span className="inline-flex rounded-md shadow-sm mt-2">
                        <Link
                            to="/"
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 active:bg-primary-700 transition ease-in-out duration-150"
                        >
                            Palaa etusivulle
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
